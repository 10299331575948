import './App.css';

function App() {
  const handleOnSubmit = (event: any) => {
    event.preventDefault();
    console.log(new FormData(event.target).getAll("username"));
  };

  return (
    <main className="App">
      <section className='App-section'>
        <img src="/static/images/professional.webp" className="App-promo-image" alt="promo" />
      </section>
      <section className='App-section'>
        <form onSubmit={handleOnSubmit}>
          <h1>Sign Up</h1>
          <p>Please fill in this form to create an account.</p>
      
          <label htmlFor="email"><b>Username</b></label>
          <input type="text" name="username" placeholder="Enter Username" required />
      
          <label htmlFor="email"><b>Email</b></label>
          <input type="text" placeholder="Enter Email" name="email" required />
          
          <label htmlFor="email"><b>Phone Number</b></label>
          <input type="phone" name="phone" placeholder="812345678" required />
      
          <p>By creating an account you agree to our <a href="#">Terms & Privacy</a>.</p>
          <button type="submit" className="btn">Sign Up</button>
          <div>
            <a href="https://www.werkspot.nl/service-pro/profile">Profile www</a>
          </div>
          <div>
            <a href="https://mobile.werkspot.nl/service-pro/profile">Profile mobile</a>
          </div>
          <div>
            <a href="https://werkspot.nl/service-pro/profile">Profile empty</a>
          </div>
        </form>
      </section>      
    </main>
  );
}

export default App;
